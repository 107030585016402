// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session__container {
  margin: 0;
  padding: 0;
}
.session__content {
  position: absolute;
  top: 79px;
  bottom: 33px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  margin: 0 0 2px 0;
  color: #000000;
  background-color: #FFFFFF;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/_session.scss"],"names":[],"mappings":"AACI;EACI,SAAA;EACA,UAAA;AAAR;AAGI;EACI,kBAAA;EACA,SAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;EACA,cAAA;AADR","sourcesContent":[".session {\n    &__container {\n        margin: 0;\n        padding: 0;\n    }\n\n    &__content {\n        position: absolute;\n        top: 79px;\n        bottom: 33px;\n        left: 0;\n        right: 0;\n        display: flex;\n        flex-direction: column;\n        justify-content: space-between;\n        height: auto;\n        margin: 0 0 2px 0;\n        color: #000000;\n        background-color: #FFFFFF;\n        overflow: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
