// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  background-color: rgb(186, 177, 177);
  outline: 1px solid #000000;
}
.drop-area__is-over {
  outline-color: #0000FF;
}
.drop-area__is-dropped {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Sections/QuestionSection/elements/DragAndDropOptions/elements/DropArea/_drop-area.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,oCAAA;EACA,0BAAA;AACJ;AACI;EACI,sBAAA;AACR;AAEI;EACI,aAAA;AAAR","sourcesContent":[".drop-area {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100px;\n    height: 50px;\n    background-color: rgb(186, 177, 177);;\n    outline: 1px solid #000000;\n\n    &__is-over {\n        outline-color: #0000FF;\n    }\n\n    &__is-dropped {\n        outline: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
