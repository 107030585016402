// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../img/btnIcons.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-header-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  color: #FFFFFF;
  font-size: 12pt;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.sub-header-button:hover {
  color: #FFFF00;
}
.sub-header-button__answer {
  background-position: left -443px;
}
.sub-header-button__answer:hover {
  background-position: left -574px;
}
.sub-header-button__calculator {
  background-position: left -2550px;
}
.sub-header-button__calculator:hover {
  background-position: left -2593px;
}
.sub-header-button__flag {
  background-position: left -973px;
}
.sub-header-button__flag:hover {
  background-position: left -1018px;
}
.sub-header-button__flag--is-flagged {
  background-position: left -1063px;
}
.sub-header-button__flag--is-flagged:hover {
  background-position: left -1063px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Buttons/SubHeaderButton/_sub-header-button.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,yDAAA;AACJ;AACI;EACI,cAAA;AACR;AAEI;EACI,gCAAA;AAAR;AAEQ;EACI,gCAAA;AAAZ;AAII;EACI,iCAAA;AAFR;AAIQ;EACI,iCAAA;AAFZ;AAMI;EACI,gCAAA;AAJR;AAMQ;EACI,iCAAA;AAJZ;AAOQ;EACI,iCAAA;AALZ;AAOY;EACI,iCAAA;AALhB","sourcesContent":[".sub-header-button {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 10px;\n    color: #FFFFFF;\n    font-size: 12pt;\n    background-image: url('/img/btnIcons.png');\n\n    &:hover {\n        color: #FFFF00;\n    }\n\n    &__answer {\n        background-position: left -443px;\n\n        &:hover {\n            background-position: left -574px;\n        }\n    }\n\n    &__calculator {\n        background-position: left -2550px;\n\n        &:hover {\n            background-position: left -2593px;\n        }\n    }\n\n    &__flag {\n        background-position: left -973px;\n\n        &:hover {\n            background-position: left -1018px;\n        }\n\n        &--is-flagged {\n            background-position: left -1063px;\n\n            &:hover {                \n                background-position: left -1063px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
