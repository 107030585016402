// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__container {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border-top: 2px solid #FFFFFF;
  color: #FFFFFF;
  background-color: #006DAA;
}
.footer__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.footer__button-left {
  padding: 0 5px;
  border-left: 2px solid #FFFFFF;
}
.footer__button-right {
  padding: 0 5px;
  border-right: 2px solid #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Footer/_footer.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,SAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,6BAAA;EACA,cAAA;EACA,yBAAA;AAAR;AAGI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AADR;AAII;EACI,cAAA;EACA,8BAAA;AAFR;AAKI;EACI,cAAA;EACA,+BAAA;AAHR","sourcesContent":[".footer {\n    &__container {\n        position: absolute;\n        bottom: 0;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        width: 100%;\n        margin: 0;\n        padding: 0;\n        border-top: 2px solid #FFFFFF;\n        color: #FFFFFF;\n        background-color: #006DAA;\n    }\n\n    &__buttons {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        padding: 5px;\n    }\n\n    &__button-left {\n        padding: 0 5px;\n        border-left: 2px solid #FFFFFF;\n    }\n\n    &__button-right {\n        padding: 0 5px;\n        border-right: 2px solid #FFFFFF;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
