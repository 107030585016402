// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../img/btnIcons.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter-button {
  padding-left: 22px;
  color: #FFFFFF;
  font-size: 12pt;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: left -90px;
}
.counter-button:hover {
  color: #FFFF00;
  background-position: left -130px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Buttons/CounterButton/_counter-button.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,cAAA;EACA,eAAA;EACA,yDAAA;EACA,+BAAA;AACJ;AACI;EACI,cAAA;EACA,gCAAA;AACR","sourcesContent":[".counter-button {\n    padding-left: 22px;\n    color: #FFFFFF;\n    font-size: 12pt;\n    background-image: url('/img/btnIcons.png');\n    background-position: left -90px;\n\n    &:hover {\n        color: #FFFF00;\n        background-position: left -130px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
