// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-header__container {
  position: absolute;
  top: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 100%;
  color: #FFFFFF;
  background-color: #5E98D9;
  border-bottom: 2px solid #FFFFFF;
}
.sub-header__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/SubHeader/_sub-header.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,SAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,yBAAA;EACA,gCAAA;AAAR;AAGI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AADR","sourcesContent":[".sub-header {\n    &__container {\n        position: absolute;\n        top: 47px;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        height: 30px;\n        width: 100%;\n        color: #FFFFFF;\n        background-color: #5E98D9;\n        border-bottom: 2px solid #FFFFFF;\n    }\n\n    &__buttons {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
