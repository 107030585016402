// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../img/btnIcons.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 14pt;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.footer-button:hover {
  color: #FFFF00;
}
.footer-button__end {
  background-position: left -1192px;
}
.footer-button__end:hover {
  background-position: left -1235px;
}
.footer-button__prev {
  background-position: left -1622px;
}
.footer-button__prev:hover {
  background-position: left -1664px;
}
.footer-button__next {
  padding-left: 0;
  padding-right: 25px;
  background-position: right -1831px;
}
.footer-button__next:hover {
  background-position: right -1872px;
}
.footer-button__navigator {
  background-position: left -1705px;
}
.footer-button__navigator:hover {
  background-position: left -1748px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Buttons/FooterButton/_footer-button.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,yDAAA;AACJ;AACI;EACI,cAAA;AACR;AAEI;EACI,iCAAA;AAAR;AAEQ;EACI,iCAAA;AAAZ;AAII;EACI,iCAAA;AAFR;AAIQ;EACI,iCAAA;AAFZ;AAMI;EACI,eAAA;EACA,mBAAA;EACA,kCAAA;AAJR;AAMQ;EACI,kCAAA;AAJZ;AAQI;EACI,iCAAA;AANR;AAQQ;EACI,iCAAA;AANZ","sourcesContent":[".footer-button {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #FFFFFF;\n    font-size: 14pt;\n    background-image: url('/img/btnIcons.png');\n\n    &:hover {\n        color: #FFFF00;\n    }\n\n    &__end {\n        background-position: left -1192px;\n\n        &:hover {\n            background-position: left -1235px;\n        }\n    }\n\n    &__prev {\n        background-position: left -1622px;\n\n        &:hover {\n            background-position: left -1664px;\n        }\n    }\n\n    &__next {\n        padding-left: 0;\n        padding-right: 25px;\n        background-position: right -1831px;\n\n        &:hover {\n            background-position: right -1872px;\n        }\n    }\n\n    &__navigator {\n        background-position: left -1705px;\n\n        &:hover {\n            background-position: left -1748px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
