// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio_options__container {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 2rem;
}
.radio_options__item {
  margin: 0.65rem 0;
}
.radio_options__item > label {
  display: flex;
  align-items: first baseline;
}
.radio_options__item--label {
  margin-left: 2rem;
}
.radio_options__item-feedback {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 1rem;
}
.radio_options__item-feedback > span {
  display: inline-block;
  padding: 3px 5px;
  min-width: 85px;
  text-align: center;
  color: #FFFFFF;
  font-size: 0.75rem;
  border-radius: 4px;
}
.radio_options__item-feedback--correct {
  background-color: #4CAF50;
}
.radio_options__item-feedback--yours {
  background-color: #00BCD4;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Sections/QuestionSection/elements/RadioOptions/_radio-options.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AAAR;AAGI;EACI,iBAAA;AADR;AAGQ;EACI,aAAA;EACA,2BAAA;AADZ;AAIQ;EACI,iBAAA;AAFZ;AAMQ;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,iBAAA;AAJZ;AAMY;EACI,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AAJhB;AAOY;EACI,yBAAA;AALhB;AAQY;EACI,yBAAA;AANhB","sourcesContent":[".radio_options {\n    &__container {\n        display: flex;\n        flex-direction: column;\n        margin: 1.5rem 2rem;\n    }\n\n    &__item {\n        margin: 0.65rem 0;\n\n        > label {            \n            display: flex;\n            align-items: first baseline;\n        }\n\n        &--label {\n            margin-left: 2rem;\n        }\n        \n\n        &-feedback {\n            display: flex;\n            flex-direction: column;\n            gap: 5px;\n            margin-left: 1rem;\n\n            > span {\n                display: inline-block;\n                padding: 3px 5px;\n                min-width: 85px;\n                text-align: center;\n                color: #FFFFFF;\n                font-size: 0.75rem;\n                border-radius: 4px;\n            }\n\n            &--correct{\n                background-color: #4CAF50;\n            }\n\n            &--yours {\n                background-color: #00BCD4;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
