// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__container {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  background-color: #006DAA;
  border-bottom: 2px solid #FFFFFF;
}
.header__title {
  margin: 0 0 0 10px;
  font-size: 1.325rem;
  font-weight: normal;
}
.header__buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Header/_header.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,MAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,cAAA;EACA,yBAAA;EACA,gCAAA;AAAR;AAGI;EACI,kBAAA;EACA,mBAAA;EACA,mBAAA;AADR;AAII;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,uBAAA;EACA,iBAAA;AAFR","sourcesContent":[".header {\n    &__container {\n        position: absolute;\n        top: 0;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        width: 100%;\n        height: 50px;\n        margin: 0;\n        padding: 0;\n        color: #FFFFFF;\n        background-color: #006DAA;\n        border-bottom: 2px solid #FFFFFF;\n    }\n\n    &__title {\n        margin: 0 0 0 10px;\n        font-size: 1.325rem;\n        font-weight: normal;\n    }\n\n    &__buttons {\n        display: flex;\n        flex-direction: column;\n        align-items: flex-end;\n        justify-content: center;\n        margin-left: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
