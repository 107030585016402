// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restricted__container {
  padding: 3rem;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Restricted/_restricted.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,kBAAA;AAAR","sourcesContent":[".restricted {\n    &__container {\n        padding: 3rem;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
