// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.packages__container {
  max-width: 70rem;
  margin: 3rem auto;
  padding: 3rem;
}
.packages__pt-content {
  display: flex;
  margin-bottom: 3rem;
}
.packages__pt-content > div {
  padding: 3rem;
  flex: 50% 1;
}
.packages__pt-comment {
  background: #607D7F;
  color: #fff;
  line-height: 1.75rem;
}
.packages__session-links {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 0 !important;
}
.packages__session-links--content {
  display: flex;
  flex-direction: column;
}
.packages__session-links--content > a {
  padding: 0.5rem;
  color: #53A5AB;
  text-decoration: underline;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Packages/_packages.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,iBAAA;EACA,aAAA;AAAR;AAGI;EACI,aAAA;EACA,mBAAA;AADR;AAGQ;EACI,aAAA;EACA,WAAA;AADZ;AAKI;EACI,mBAAA;EACA,WAAA;EACA,oBAAA;AAHR;AAMI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,yBAAA;AAJR;AAMQ;EACI,aAAA;EACA,sBAAA;AAJZ;AAMY;EACI,eAAA;EACA,cAAA;EACA,0BAAA;EACA,eAAA;AAJhB","sourcesContent":[".packages {\n    &__container {\n        max-width: 70rem;\n        margin: 3rem auto;\n        padding: 3rem;\n    }\n\n    &__pt-content {\n        display: flex;\n        margin-bottom: 3rem;\n\n        > div {\n            padding: 3rem;\n            flex: 50%;\n        }\n    }\n\n    &__pt-comment {\n        background: #607D7F;\n        color: #fff;\n        line-height: 1.75rem;\n    }\n\n    &__session-links {\n        display: flex;\n        flex-direction: column;\n        gap: 3rem;\n        padding-top: 0 !important;\n\n        &--content {\n            display: flex;\n            flex-direction: column;\n    \n            > a {\n                padding: 0.5rem;\n                color: #53A5AB;\n                text-decoration: underline;\n                cursor: pointer;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
