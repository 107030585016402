// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.package-instruction__container {
  padding: 1rem 2rem 1rem 1rem;
  font-family: arial;
}
.package-instruction__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.package-instruction__header > h5 {
  font-family: arial;
  font-size: 1rem;
}
.package-instruction__content > * {
  font-size: 0.9125rem;
}
.package-instruction__content > h6 {
  margin-bottom: 1rem;
}
.package-instruction__content > p {
  padding-bottom: 1rem;
}
.package-instruction__content > ul > li {
  margin-bottom: 0.5rem;
}
.package-instruction__content > table {
  margin: 2rem 0;
  border-collapse: "collapse";
}
.package-instruction__content > table td {
  max-width: 15rem;
  text-align: center;
}
.package-instruction__content > table td:first-child {
  text-align: left;
  font-weight: bold;
}
.package-instruction__content > table td p {
  padding: 0.5rem 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Sections/PackageInstruction/_package-instruction.scss"],"names":[],"mappings":"AACI;EACI,4BAAA;EACA,kBAAA;AAAR;AAGI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,qBAAA;AADR;AAGQ;EACI,kBAAA;EACA,eAAA;AADZ;AAMQ;EACI,oBAAA;AAJZ;AAOQ;EACI,mBAAA;AALZ;AAQQ;EACI,oBAAA;AANZ;AASQ;EACI,qBAAA;AAPZ;AAUQ;EACI,cAAA;EACA,2BAAA;AARZ;AAUY;EACI,gBAAA;EACA,kBAAA;AARhB;AAUgB;EACI,gBAAA;EACA,iBAAA;AARpB;AAWgB;EACI,oBAAA;AATpB","sourcesContent":[".package-instruction {\n    &__container {\n        padding: 1rem 2rem 1rem 1rem;\n        font-family: arial;\n    }\n\n    &__header {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: flex-end;\n        \n        > h5 {\n            font-family: arial;\n            font-size: 1rem;\n        }\n    }\n\n    &__content {\n        > * {\n            font-size: 0.9125rem;\n        }\n\n        > h6 {\n            margin-bottom: 1rem;\n        }\n\n        > p {\n            padding-bottom: 1rem;\n        }\n\n        > ul > li {\n            margin-bottom: 0.5rem;\n        }\n\n        > table {\n            margin: 2rem 0;\n            border-collapse: 'collapse';\n\n            td {\n                max-width: 15rem;\n                text-align: center;\n\n                &:first-child {\n                    text-align: left;\n                    font-weight: bold;\n                }\n\n                p {\n                    padding: 0.5rem 1rem;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
