// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview-section__container {
  padding: 1rem;
  font-family: arial;
}
.overview-section__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.overview-section__header > h5 {
  font-family: arial;
  font-size: 1rem;
}
.overview-section__content > * {
  font-size: 0.9rem;
}
.overview-section__content > h6 {
  font-size: 1rem;
  margin-bottom: 1rem;
}
.overview-section__content > p {
  padding-bottom: 1rem;
}
.overview-section__table {
  margin: 2rem;
}
.overview-section__table > thead > tr > th {
  padding-bottom: 1rem;
}
.overview-section__table > thead > tr > th:first-child {
  text-align: left;
}
.overview-section__table-row > td {
  min-width: 8rem;
  text-align: center;
}
.overview-section__table-row > td:first-child {
  min-width: 12rem;
  padding: 0.5rem 0;
  font-weight: bold;
  text-align: left;
}
.overview-section__table-row--link {
  text-decoration: underline;
  cursor: pointer;
}
.overview-section__table-row--link:hover {
  color: #006DAA;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Sections/OverviewSection/_overview-section.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,kBAAA;AAAR;AAGI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AADR;AAGQ;EACI,kBAAA;EACA,eAAA;AADZ;AAMQ;EACI,iBAAA;AAJZ;AAOQ;EACI,eAAA;EACA,mBAAA;AALZ;AAQQ;EACI,oBAAA;AANZ;AAUI;EACI,YAAA;AARR;AAUQ;EACI,oBAAA;AARZ;AAUY;EACI,gBAAA;AARhB;AAaY;EACI,eAAA;EACA,kBAAA;AAXhB;AAagB;EACI,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;AAXpB;AAeY;EACI,0BAAA;EACA,eAAA;AAbhB;AAegB;EACI,cAAA;AAbpB","sourcesContent":[".overview-section {\n    &__container {\n        padding: 1rem;\n        font-family: arial;\n    }\n\n    &__header {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        \n        > h5 {\n            font-family: arial;\n            font-size: 1rem;\n        }\n    }\n\n    &__content {\n        > * {\n            font-size: 0.9rem;\n        }\n\n        > h6 {\n            font-size: 1rem;\n            margin-bottom: 1rem;\n        }\n\n        > p {\n            padding-bottom: 1rem;\n        }\n    }\n\n    &__table {\n        margin: 2rem;\n\n        > thead > tr > th {\n            padding-bottom: 1rem;\n\n            &:first-child {\n                text-align: left;\n            }\n        }\n\n        &-row {\n            > td {\n                min-width: 8rem;\n                text-align: center;\n\n                &:first-child {\n                    min-width: 12rem;\n                    padding: 0.5rem 0;\n                    font-weight: bold;\n                    text-align: left;\n                }\n            }\n\n            &--link {\n                text-decoration: underline;\n                cursor: pointer;\n\n                &:hover {\n                    color: #006DAA;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
