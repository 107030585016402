// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  cursor: grab;
}
.answer__is-dragging {
  opacity: 0.5;
  border-color: #0000FF;
}
.answer__is-hidden {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Sections/QuestionSection/elements/DragAndDropOptions/elements/Answer/_answer.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,yBAAA;EACA,YAAA;AACJ;AACI;EACI,YAAA;EACA,qBAAA;AACR;AAEI;EACI,kBAAA;AAAR","sourcesContent":[".answer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100px;\n    height: 50px;\n    background-color: #FFFFFF;\n    border: 1px solid #000000;\n    cursor: grab;\n\n    &__is-dragging {\n        opacity: 0.5;\n        border-color: #0000FF;\n    }\n\n    &__is-hidden {\n        visibility: hidden;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
