// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-instruction__container {
  padding: 1rem;
  font-family: arial;
  font-size: 1rem;
}
.section-instruction__content > * {
  font-size: 0.9125rem;
}
.section-instruction__content h6 {
  margin: 1rem 0;
  font-size: 0.9125rem;
}
.section-instruction__content table {
  margin: 2rem 0;
  border-collapse: "collapse";
}
.section-instruction__content table td {
  width: 8rem;
  text-align: center;
}
.section-instruction__content table td p {
  padding: 0.25rem;
}
.section-instruction__content p {
  padding-bottom: 1.25rem;
}
.section-instruction__content ul > li {
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Session/elements/Sections/SectionInstruction/_section-instruction.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,kBAAA;EACA,eAAA;AAAR;AAIQ;EACI,oBAAA;AAFZ;AAKQ;EACI,cAAA;EACA,oBAAA;AAHZ;AAMQ;EACI,cAAA;EACA,2BAAA;AAJZ;AAMY;EACI,WAAA;EACA,kBAAA;AAJhB;AAMgB;EACI,gBAAA;AAJpB;AASQ;EACI,uBAAA;AAPZ;AAUQ;EACI,qBAAA;AARZ","sourcesContent":[".section-instruction {\n    &__container {\n        padding: 1rem;\n        font-family: arial;\n        font-size: 1rem;\n    }\n\n    &__content {\n        > * {\n            font-size: 0.9125rem;\n        }\n\n        h6 {\n            margin: 1rem 0;\n            font-size: 0.9125rem;\n        }\n\n        table {\n            margin: 2rem 0;\n            border-collapse: 'collapse';\n\n            td {\n                width: 8rem;\n                text-align: center;\n\n                p {\n                    padding: 0.25rem;\n                }\n            }\n        }\n\n        p {\n            padding-bottom: 1.25rem;\n        }\n        \n        ul > li {\n            margin-bottom: 0.5rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
